<template>
    <div>
        <div class="item">
            <img src="http://api.tengbocn.com/upload/image/daping1.jpg" @click="ishowModal">
            <p><b>模板名称：</b>蓝色模板</p>
            <p><b>建议分辨率：</b>1920*1080</p>

        </div>
        <div class="item">
            <img src="http://api.tengbocn.com/upload/image/daping2.jpg" @click="iishowModal">
            <p><b>模板名称：</b>绿色模板</p>
            <p><b>建议分辨率：</b>1920*1080</p>

        </div>

        <a-modal
                title="Title"
                :visible="ivisible"
                :confirm-loading="iconfirmLoading"
                @ok="ihandleOk"
                @cancel="ihandleCancel" width="1024" height="768">
            <div><img src="http://api.tengbocn.com/upload/image/daping1.jpg" style="width: 100%; height: auto"></div>
        </a-modal>

        <a-modal
                title="Title"
                :visible="iivisible"
                :confirm-loading="iiconfirmLoading"
                @ok="iihandleOk"
                @cancel="iihandleCancel" width="1024" height="768">
            <p><img src="http://api.tengbocn.com/upload/image/daping2.jpg" style="width: 100%; height: auto"></p>
        </a-modal>

    </div>
</template>

<script>

    export default {
        name: "dapingmuban",
        data() {
            return {
                iModalText: 'Content of the modal',
                ivisible: false,
                iconfirmLoading: false,

                iiModalText: 'Content of the modal',
                iivisible: false,
                iiconfirmLoading: false,

                iiiModalText: 'Content of the modal',
                iiivisible: false,
                iiiconfirmLoading: false,
            };
        },
        methods: {
            ishowModal() {
                this.ivisible = true;
            },
            ihandleOk(e) {
                this.iModalText = 'The modal will be closed after two seconds';
                this.iconfirmLoading = true;
                setTimeout(() => {
                    this.ivisible = false;
                    this.iconfirmLoading = false;
                }, 2000);
            },
            ihandleCancel(e) {
                console.log('Clicked cancel button');
                this.ivisible = false;
            },

            iishowModal() {
                this.iivisible = true;
            },
            iihandleOk(e) {
                this.iiModalText = 'The modal will be closed after two seconds';
                this.iiconfirmLoading = true;
                setTimeout(() => {
                    this.iivisible = false;
                    this.iiconfirmLoading = false;
                }, 2000);
            },
            iihandleCancel(e) {
                console.log('Clicked cancel button');
                this.iivisible = false;
            },

        },

    }
</script>

<style scoped>
.item{
    float: left;
    margin: 15px;
    width: 420px;
    /*height: 312px;*/
    /*background: #efefef;*/
}
.item img{ width: 420px; height: auto;}
.item p{ margin: 0; padding: 15px 0 0 0; line-height: 1}
.ant-modal{ width: 1024px!important;}
</style>
